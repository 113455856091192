import React, { useState } from 'react';
// @ts-ignore
import IAMF from '../../assets/audio/if_a_man_falls.mp3';
// @ts-ignore
import hostage from '../../assets/audio/this_is_a_hostage_situation.mp3';
// @ts-ignore
import SATS from '../../assets/audio/staring_at_the_sun.mp3';
// @ts-ignore
import arentyouproud from '../../assets/audio/arent_you_proud.mp3';
// @ts-ignore
import brokenintwo from '../../assets/audio/broken_in_two.mp3';
// @ts-ignore
import carsinalake from '../../assets/audio/cars_in_a_lake.mp3';
// @ts-ignore
import counting from '../../assets/audio/counting.mp3';
// @ts-ignore
import gluetrap from '../../assets/audio/glue_trap.mp3';
// @ts-ignore
import tastelike from '../../assets/audio/taste_like.mp3';
// @ts-ignore
import wishuweredead from '../../assets/audio/wishuweredead.mp3';
// cover art
import IAMFcover from '../../assets/pictures/music/audio/IAMF.png';
import hostagecover from '../../assets/pictures/music/audio/hostage.png';
import SATScover from '../../assets/pictures/music/audio/SATS.png';
import deadbeatcover from '../../assets/pictures/music/audio/deadbeat.png';
import { MusicPlayer } from '../general';
import AlbumPlayer from '../general/AlbumPlayer'; // Import AlbumPlayer
import spotifyIcon from '../../assets/pictures/spotify.png';
import appleIcon from '../../assets/pictures/applemusic.png';

export interface ProjectsProps {}


// Props for SocialBox component
interface SocialBoxProps {
    icon: string;
    link: string;
}

// SocialBox component to display social media icons with links
const SocialBox: React.FC<SocialBoxProps> = ({ link, icon }) => {
    return (
        <a rel="noreferrer" target="_blank" href={link}>
            <div className="big-button-container" style={styles.social}>
                <img src={icon} alt="" style={styles.socialImage} />
            </div>
        </a>
    );
};


const Projects: React.FC<ProjectsProps> = (props) => {
    // State to track which player is currently active (playing)
    const [playingPlayer, setPlayingPlayer] = useState<string | null>(null);

    // State for password checking
    const [password, setPassword] = useState('');
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [error, setError] = useState(''); // State for error message


    const albumPlaylist = [
        { title: 'lol', src: gluetrap, subtitle: 'monty.pk' },
        { title: 'nice try', src: brokenintwo, subtitle: 'monty.pk' },
        { title: 'theres no songs in here', src: wishuweredead, subtitle: 'monty.pk' },
        { title: "but i am impressed", src: arentyouproud, subtitle: 'monty.pk' },
        { title: "you nearly got me", src: carsinalake, subtitle: 'monty.pk' },
        { title: 'but you didnt', src: tastelike, subtitle: 'monty.pk' },
        { title: 'if a man falls', src: IAMF, subtitle: 'monty.pk' },
        { title: 'come see me on tour', src: counting, subtitle: 'monty.pk' },
    ];

    const checkPassword = () => {
        if (password === 'mikeferriisgay') { // set password
            setIsAuthorized(true);
            setError('');
        } else {
            setError("nice try...");
        }
    };

    return (
        <div className="site-page-content">
            <div style={styles.header}>
                <h1>music</h1>
                <div style={styles.socials}>
                    <SocialBox
                        icon={spotifyIcon}
                        link={'https://open.spotify.com/artist/3L2O6zqeWf9jdUFVihAB5g?si=I8J7ZvNwSo-ZX3E9Q3aUQA'}
                    />
                    <SocialBox
                        icon={appleIcon}
                        link={'https://music.apple.com/us/artist/monty-pk/1446985385'}
                    />
                </div>
            </div>
            <div className="text-block"></div>

            {/* Blurred Content with Overlay */}
            <div style={{ position: 'relative', display: 'block', width: '100%' }}>

                {/* Password Input */}
                {!isAuthorized && (
                    <div style={styles.overlay}>
                        {/*<p>hidden content</p>*/}
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="password"
                        />
                        <br />
                        <button
                            className='site-button'
                            //style={styles.button}
                            onClick={checkPassword}>submit</button>
                        {/* Display error message if password is incorrect */}
                        {error && <p style={styles.errorText}>{error}</p>}    
                    </div>
                )}

                {/* Blurred Content */}
                <div style={!isAuthorized ? { filter: 'blur(9px)', display: 'block', width: '100%', pointerEvents: 'none' } : { display: 'block', width: '100%' }}> 
                <div className="text-block" style={{ marginBottom: '0px' }}> {/* Reduced marginBottom for closer spacing */}
                        <h2>got ur ass</h2>
                        <br />
                        <p>
                            theres nothing here
                        <br />
                            hah
                        </p>
                        <br />
                        <div className="captioned-image">
                            <img src={deadbeatcover} alt="deadbeat" />
                            <p>
                                <sub>
                                    <b>Figure 1:</b> Placeholder image...
                                </sub>
                            </p>
                        </div>
                    </div>
                    
                    <AlbumPlayer
                        playlist={albumPlaylist}
                        playingPlayer={playingPlayer}
                        setPlayingPlayer={setPlayingPlayer}
                        playerId="albumPlayer"
                    />
                </div>
            </div>

            <br />
            <br />
            <br />
            <h2>if a man falls</h2>
            <br />
            <p>
                got me asking myself in a whistle-stop town...
            <br />
                if a man falls, does he even make a sound?
            </p>
            <br />
            <div className="captioned-image">
                <img src={IAMFcover} alt="if a man falls" />
                <p>
                    <sub>
                        <b>Figure 1:</b> Cover art shot in Amboy, CA.
                    </sub>
                </p>
            </div>

            {/* MusicPlayer for single track with independent playback control */}
            <MusicPlayer
                src={IAMF}
                title="if a man falls"
                subtitle="monty.pk"
                playingPlayer={playingPlayer}
                setPlayingPlayer={setPlayingPlayer}
                playerId="musicPlayer1"
            />

            <br />
            <br />
            <br />
            <h2>this is a hostage situation!</h2>
            <br />
            <p>
                this one may have been in poor taste
            </p>
            <br />
            <div className="captioned-image">
                <img src={hostagecover} alt="this is a hostage situation!" />
                <p>
                    <sub>
                        <b>Figure 2:</b> Custom keychain from ap3x4nn3.
                    </sub>
                </p>
            </div>

            <MusicPlayer
                src={hostage}
                title="this is a hostage situation!"
                subtitle="monty.pk"
                playingPlayer={playingPlayer}
                setPlayingPlayer={setPlayingPlayer}
                playerId="musicPlayer2"
            />
            <br />
            <br />
            <br />
            <h2>STARING AT THE SUN</h2>
            <br />
            <p>
                yelling, hence the all caps
            </p>
            <br />
            <div className="captioned-image">
                <img src={SATScover} alt="this is a hostage situation!" />
                <p>
                    <sub>
                        <b>Figure 3:</b> Ignore the fleshy color. That is the sun.
                    </sub>
                </p>
            </div>

            <MusicPlayer
                src={SATS}
                title="STARING AT THE SUN"
                subtitle="monty.pk"
                playingPlayer={playingPlayer}
                setPlayingPlayer={setPlayingPlayer}
                playerId="musicPlayer3"
            />
            <br />
            <br />
            <br />
            <p>
                there's a few more. check spotify.<br />all this for a damn website.<br />who even looks at these.
            </p>

        </div>
    );
};

// Styles for the component
const styles: StyleSheetCSS = {
    form: {
        flexDirection: 'column',
        marginTop: 32,
    },
    formItem: {
        marginTop: 4,
        marginBottom: 16,
    },
    socialImage: {
        width: 36,
        height: 36,
    },
    buttons: {
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    formInfo: {
        textAlign: 'right',
        flexDirection: 'column',
        alignItems: 'flex-end',
        paddingLeft: 24,
    },
    star: {
        paddingRight: 4,
        color: 'red',
    },
    button: {
        minWidth: 184,
        height: 32,
    },
    header: {
        alignItems: 'flex-end',
        justifyContent: 'space-between',
    },
    socials: {
        marginBottom: 16,
        justifyContent: 'flex-end',
    },
    social: {
        width: 4,
        height: 4,
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: 8,
    },
    overlay: {
        position: 'absolute',
        top: '35%',
        left: '35%',
        right: '35%',
        bottom: 0,
        //backgroundColor: 'rgba(255, 255, 255, 0.8)', // Semi-transparent background
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        //justifyContent: 'center',
        zIndex: 2, // Ensures overlay is above blurred content
    },
    passwordInput: {
        width: '20px', // Shorter width for input box
        marginTop: '10px',
        padding: '8px',
        fontSize: '16px',
    },
    submitButton: {
    //    ...styles.button,
        marginTop: '10px',
    },
    errorText: {
        color: 'red', // Display error message in red
        marginTop: '8px',
        fontSize: '14px',
    },
};

export default Projects;