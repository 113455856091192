import React from 'react';
import colors from '../../constants/colors';

const ticketData = [
    {
        city: "Seattle, WA",
        date: "January 17, 2025",
        venue: "Barboza",
        link: "https://www.axs.com/events/726532/sophie-truax-tickets?skin=barboza",
    },
    {
        city: "Portland, OR",
        date: "January 18, 2025",
        venue: "Holocene",
        link: "https://www.etix.com/ticket/p/87556129/sophie-truax-all-ages-portland-holocene?clickref=1011lA7YCJ2i",
    },
    {
        city: "San Francisco, CA",
        date: "January 20, 2025",
        venue: "Cafe Du Nord",
        link: "https://www.ticketweb.com/event/sophie-truax-cafe-du-nord-tickets/13661994?irgwc=1&clickid=1T4zgpQbixyKWUS2XORQfyf%3AUks0T4WtQWPx0U0&camefrom=CFC_BUYAT_219208&impradid=219208&REFERRAL_ID=tmfeedbuyat219208&wt.mc_id=aff_BUYAT_219208&utm_source=219208-Bandsintown&impradname=Bandsintown&utm_medium=affiliate&ircid=4272",
    },
    {
        city: "Los Angeles, CA",
        date: "January 21, 2025",
        venue: "Moroccan Lounge",
        link: "https://www.ticketmaster.com/event/0900614FD55C3CB3?irgwc=1&clickid=1T4zgpQbixyKWUS2XORQfyf%3AUks0T4XRQWPx0U0&camefrom=CFC_BUYAT_219208&impradid=219208&REFERRAL_ID=tmfeedbuyat219208&wt.mc_id=aff_BUYAT_219208&utm_source=219208-Bandsintown&impradname=Bandsintown&utm_medium=affiliate&ircid=4272",
    },
    {
        city: "Philadelphia, PA",
        date: "January 23, 2025",
        venue: "World Cafe Live",
        link: "https://www.etix.com/ticket/p/75454950/sophie-truax-philadelphia-music-hall-at-world-cafe-live?clickref=1100lzYXi5UA",
    },
    {
        city: "New York, NY",
        date: "January 24, 2025",
        venue: "The Mercury Lounge",
        link: "https://www.ticketmaster.com/event/0000614ECBF1300D?irgwc=1&clickid=1T4zgpQbixyKWUS2XORQfyf%3AUks0T9xBQWPx0U0&camefrom=CFC_BUYAT_219208&impradid=219208&REFERRAL_ID=tmfeedbuyat219208&wt.mc_id=aff_BUYAT_219208&utm_source=219208-Bandsintown&impradname=Bandsintown&utm_medium=affiliate&ircid=4272",
    },
    {
        city: "Cambridge, MA",
        date: "January 25, 2025",
        venue: "The Middle East Upstairs",
        link: "https://www.ticketweb.com/event/sophie-truax-middle-east-upstairs-tickets/13668754?pl=mideastclub&irgwc=1&clickid=1T4zgpQbixyKWUS2XORQfyf%3AUks0T925QWPx0U0&camefrom=CFC_BUYAT_219208&impradid=219208&REFERRAL_ID=tmfeedbuyat219208&wt.mc_id=aff_BUYAT_219208&utm_source=219208-Bandsintown&impradname=Bandsintown&utm_medium=affiliate&ircid=4272",
    },
];

export interface TicketsProps {}

const Tickets: React.FC<TicketsProps> = () => {
    return (
        <div className="site-page-content">
            <div style={styles.header}>
                <h1>tickets</h1>
            </div>
            <div className="text-block"></div>
            <h2>monty.pk World Tour</h2>

            <br />

            <p>- supporting Sophie Truax</p>
            <p>- new music</p>
            <p>- exclusive .pk hat</p>
            <p>- see below</p>

            <br />


            {ticketData.map((ticket, index) => (
                <div key={index} style={styles.ticketContainer}>
                    <div style={styles.ticketInfo}>
                        <h3 style={styles.ticketCity}>{ticket.city}</h3>
                        <p style={styles.ticketDate}>{ticket.date}</p>
                        <p style={styles.ticketVenue}>{ticket.venue}</p>
                    </div>
                    <a
                        href={ticket.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="site-button"
                        style={styles.buyButton}
                    >
                        Buy Tickets
                    </a>
                </div>
            ))}
        </div>
    );
};

const styles: { [key: string]: React.CSSProperties } = {
    header: {
        alignItems: 'flex-end',
        justifyContent: 'space-between',
    },
    ticketContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 30,
        width: '95%',
        padding: 16,
        marginBottom: 24,
        borderRadius: 2,
        border: '1px solid #333',
        backgroundColor: '#f9f9f9',
        borderLeftWidth: 2, // Thicker left border
        borderTopWidth: 2, // Thicker left border
    },
    ticketInfo: {
        display: 'grid',
        gridTemplateColumns: '1.4fr 1fr 1fr', // Equal column widths for city, date, and venue
        gap: 16, // Adds spacing between the sections
        flex: 1, // Ensures the ticketInfo stretches as much as possible
    },
    ticketCity: {
        display: 'flex',
        alignItems: 'center', // Vertically centers the text
        fontSize: 18,
        margin: 0,
        fontWeight: 'bold',
    },
    ticketDate: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 16,
        margin: 0,
        //color: '#444',
    },
    ticketVenue: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 16,
        margin: 0,
        //color: '#444',
        textAlign: 'center', // Centers the text in each section
    },
    buyButton: {
        flexShrink: 0, // Prevents the buyButton from shrinking when space is tight
        padding: '8px 16px',
        color: '#fff',
        backgroundColor: '#007bff',
        borderRadius: 4,
        textDecoration: 'none',
        fontWeight: 'bold',
    },
};

export default Tickets;