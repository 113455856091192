import React from 'react';
import { Link } from '../general';

import pkgif from '../../assets/pictures/pkgif.gif';
import { useNavigate } from 'react-router-dom';

export interface HomeProps {}

const Home: React.FC<HomeProps> = (props) => {

    return (
        <div style={styles.page}>
            <div style={styles.header}>
                <h2>monty.pk website</h2>
            </div>
            <div style={styles.pkContainer}>
                <img src={pkgif} alt="" style={styles.image} />
            </div>
            <div style={styles.buttons}>
                <Link
                    containerStyle={styles.link}
                    to="music"
                    text="MUSIC"
                />

                <Link
                    containerStyle={styles.link}
                    to="tickets"
                    text="TICKETS"
                />
                
                <Link
                    containerStyle={styles.link}
                    to="contact"
                    text="CONTACT"
                />
            </div>

        </div>
    );
};

const styles: StyleSheetCSS = {
    page: {
        left: 0,
        right: 0,
        top: 0,
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%',
    },
    header: {
        textAlign: 'center',
        marginBottom: 0,
        marginTop: 0,

        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    buttons: {
        justifyContent: 'space-between',
    },
    image: {
        width: '30%', // Adjust the size as needed
        maxWidth: '300px', // Adjust the max size as needed
        height: 'auto',
    },
    link: {
        padding: 16,
    },
    pkContainer: {
        marginTop: 0,
        width: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
    },
    name: {
        fontSize: 72,
        marginBottom: 16,
        lineHeight: 0.9,
    },
};

export default Home;
