import React, { useEffect, useState } from 'react';
import { Link } from '../general';
import { useLocation, useNavigate } from 'react-router-dom';
import pkgif from '../../assets/pictures/pkgif.gif';

export interface VerticalNavbarProps {}

const VerticalNavbar: React.FC<VerticalNavbarProps> = (props) => {
    const location = useLocation();
    const [isHome, setIsHome] = useState(false);

    useEffect(() => {
        if (location.pathname === '/') {
            setIsHome(true);
        } else {
            setIsHome(false);
        }
    }, [location.pathname]);

    return !isHome ? (
        <div style={styles.navbar}>
            <div style={styles.header}>
                <h1 style={styles.headerText}>monty.pk</h1>
                <h3 style={styles.headerSub}>website</h3>
                <img src={pkgif} alt="" style={styles.image} />
            </div>
            <div style={styles.links}>
                <Link containerStyle={styles.link} to="" text="HOME" />
                <Link containerStyle={styles.link} to="music" text="MUSIC" />
                <Link containerStyle={styles.link} to="tickets" text="TICKETS" />
                <Link containerStyle={styles.link} to="contact" text="CONTACT" />
            </div>
        </div>
    ) : null;
};

const styles: StyleSheetCSS = {
    navbar: {
        width: 300,
        height: '100%',
        flexDirection: 'column',
        paddingTop: 48,
        boxSizing: 'border-box',
        position: 'fixed',
        overflow: 'hidden',
    },
    header: {
        flexDirection: 'column',
        marginBottom: 30,
    },
    headerText: {
        fontSize: 38,
        lineHeight: 1,
        paddingLeft: 48,
    },
    headerSub: {
        marginTop: 12,
        paddingLeft: 48,
    },
    link: {
        marginBottom: 32,
    },
    links: {
        flexDirection: 'column',
        //justifyContent: 'center',
        paddingLeft: 48,
    },
    image: {
        width: '40%', // Adjust the size as needed
        height: 'auto',
        paddingLeft: 30,
        paddingTop: 10,
    },
};

export default VerticalNavbar;